export const colors = {
  primary: '#128C87',
  primaryDark: '#16736f',
  secondary: '#073745',
  lightTeal: '#E3F3F3',
  footer: {
    background: '#043946',
  },
  white: '#ffffff',
  black: '#000000',
  backgroundGray: '#F1F1F1',
  lightestGray: '#D8D8D81A',
  lighterGray: '#E0E0E0',
  lightGray: '#EAEAEA',
  mediumGray: '#8D8D8D',
  darkerGray: '#717171',
  darkGray: '#3F3E3E',
  purple: '#5600C1',
  gold: '#bd8b1e',
  red: '#f00',
  darkRed: '#b90303',
  quizSuccess: '#128C48',
  quizCorrectBackground: '#d4edda',
  quizFail: '#e60e0e',
  quizWrongBackground: '#f8d7da',
  greenAqua: '#128C87',
  darkerGreenAqua: '#0d4e4b',
  alertSuccess: '#07BB8899',
  alertDanger: '#FAC1B6',
  alertDangerBorder: '#f5c6cb',
  alertWarningBorder: '#FFEFBB',
  alertWarning: '#ffeeba;',
  inputBorderColor: '#ced4da',
  inputColor: '#3F3E3E',
  disabledGray: '#E9ECEF',
  contrastedOutline: '#800080',
};

// Still need to determine what the idea smMobile breakpoint will be
const breakpoints = {
  xsMobile: '320px',
  smMobile: '',
  mdMobile: '480px',
  lgMobile: '576px',
  smTablet: '768px',
  lgTablet: '992px',
  smDesktop: '1200px',
  lgDesktop: '1920px',
};

export default {
  colors,
  breakpoints,
  fonts: {
    title: 'Roboto slab, serif',
    heading: 'Roboto, sans-serif',
    paragraph: 'Roboto, sans-serif',
  },
  fontWeightLight: 400,
  fontWeightNormal: 500,
  fontWeightBold: 700,
  borderRadius: '0.25rem',
  boxShadow: `0px 0px 8px ${colors.mediumGray}`,
  link: {
    color: '#16736f',
  },
  button: {
    colors: {
      inactive: '#16736f',
    },
    backgroundColor: '#0f7571',
    rounded: '100px',
  },
};

export const AdaHighlight = () => `
  &:focus-within {
    outline: none;
    box-shadow: 0 0 0 3px ${colors.contrastedOutline};
  }
`;
