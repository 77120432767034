import ReactGA from 'react-ga4';

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
const agencyDimensionId = process.env.REACT_APP_GOOGLE_ANALYTICS_AGENCY_DIMENSION;
const stateDimensionId = process.env.REACT_APP_GOOGLE_ANALYTICS_STATE_DIMENSION;
const regionDimensionId = process.env.REACT_APP_GOOGLE_ANALYTICS_REGION_DIMENSION;

export function gaEvent(data) {
  if (trackingId) {
    ReactGA.event(data);
  }
}

export function gaModalView(data) {
  if (trackingId) {
    ReactGA.modalview(data);
  }
}

export function gaSet(fieldsObject, trackerNames) {
  if (trackingId) {
    ReactGA.set(fieldsObject, trackerNames);
  }
}

export function gaSetUserId(userId) {
  if (trackingId) {
    gaSet({ userId });
  }
}

export function gaSetAgencyDimension(agency) {
  if (trackingId && agencyDimensionId) {
    gaSet({ [agencyDimensionId]: agency });
  }
}

export function gaSetStateDimension(state) {
  if (trackingId && stateDimensionId) {
    gaSet({ [stateDimensionId]: state });
  }
}

export function gaSetRegionDimension(region) {
  if (trackingId && regionDimensionId) {
    gaSet({ [regionDimensionId]: region });
  }
}

export function sendPageView(location) {
  if (trackingId) {
    // Wrap in a setTimeout so that the pageview event will
    // hopefully occur after react-helmet updates the page title.
    // Without this, the page title shown in GA will often be the previous page.
    // https://github.com/nfl/react-helmet/issues/189#issuecomment-336292754
    setTimeout(() => {
      ReactGA.set({ page: location.pathname });
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }, 0);
  }
}
