import React from 'react';

export const QuizContext = React.createContext({});

export function QuizProvider({ children }) {
  const [currentQuizAttempt, setCurrentQuizAttempt] = React.useState(null);

  return (
    <QuizContext.Provider
      value={{
        currentQuizAttempt,
        setCurrentQuizAttempt,
      }}
    >
      {children}
    </QuizContext.Provider>
  );
}

export const QuizConsumer = QuizContext.Consumer;
