import useGetCustomPages from './useGetCustomPages';

/*
 * Recurses over allPages to generate a tree for the sidebar menu.
 * Returns an array with the various root pages at the top level.
 * Each leaf in each subtree has a reference to its root.
 */
export function buildSidebarTree(roots, allPages, menuRoot = null) {
  return roots.map(root => {
    const children = allPages.filter(page => page.parent === root.id);
    return {
      ...root,
      menuRoot,
      children: buildSidebarTree(children, allPages, !menuRoot ? root.id : menuRoot),
    };
  });
}

export { useGetCustomPages };
