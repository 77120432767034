import React from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet';
import { useLocation, useHistory } from 'react-router-dom';
import Footer from './components/Footer';
import Nav from './components/Nav';
import { QuizProvider } from './contexts/quizContext';
import { ErrorProvider } from 'contexts/ErrorContext';
import { setToken, getUserData } from 'utils/auth';
import Routes from './routes';
import { useGetCustomPages } from 'services/CustomPages';
import GoogleAnalyticsListener from 'components/GoogleAnalyticsListener';
import SessionExpirationModal from 'components/SessionExpirationModal';
import UserInfoConfirmationModal from 'components/UserInfoConfirmationModal';

const SiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
`;

const SiteContent = styled.div`
  flex: 1 0 auto;
`;

const App = () => {
  const location = useLocation();
  const { push: redirectTo } = useHistory();
  const { loading: customPagesLoading, customPages } = useGetCustomPages();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  if (token) {
    setToken(token);
    redirectTo('/');
  }

  const rootCustomPages = customPages.filter(page => page.parent === null);

  if (customPagesLoading) {
    return null;
  }

  const userData = getUserData();

  return (
    <GoogleAnalyticsListener
      trackingId={process.env.REACT_APP_GOOGLE_ANALYTICS_ID}
      userId={userData?.id}
      agencyName={userData?.memberAgency?.name}
      regionId={userData?.memberAgency?.region}
      stateId={userData?.memberAgency?.state}
    >
      <SiteWrapper role='main'>
        <Helmet defaultTitle='Home' titleTemplate='Infinitec - %s' />
        <SiteContent>
          <ErrorProvider>
            <QuizProvider>
              {location.pathname !== '/login' && <Nav customPages={rootCustomPages} />}
              <Routes customPages={customPages} />
            </QuizProvider>
          </ErrorProvider>
        </SiteContent>
        <SessionExpirationModal />
        <UserInfoConfirmationModal />
        <Footer />
      </SiteWrapper>
    </GoogleAnalyticsListener>
  );
};

export default App;
