import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import { ErrorMessage } from '../index';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
};

const InputWrapper = styled.div`
  border: 1px solid ${theme('colors.lightGray')};
  padding: 0.33rem;
`;

function FileInput({ name, label, onChange, accept, disabled = false }) {
  const [fieldValue, setFieldValue] = React.useState();

  return (
    <Field name={name} type='file'>
      {({ input, meta }) => {
        const handleChange = e => {
          setFieldValue(e.target.value);
          onChange && onChange(e);
        };

        return (
          <InputWrapper>
            {label && <label>{label}</label>}
            <input
              type='file'
              {...input}
              accept={accept}
              onChange={handleChange}
              value={fieldValue}
              disabled={disabled}
            />
            {meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
          </InputWrapper>
        );
      }}
    </Field>
  );
}

FileInput.propTypes = propTypes;

export default FileInput;
