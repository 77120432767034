import { isAuthenticated, getUserData } from 'utils/auth';

export const ROLE_SUPER_ADMIN = 'superadmin';
export const ROLE_UCP_ADMIN = 'ucp_admin';
export const ROLE_CONTENT_ADMIN = 'content_admin';
export const ROLE_QUIZ_ADMIN = 'quiz_admin';
export const ROLE_TRAINING_ADMIN = 'training_admin';
export const ROLE_USER_ADMIN = 'user_admin';
export const ROLE_USER = 'user';

export const ACCESS_LEVEL_MEMBER = 'member';

export const NO_CONTRACT_MESSAGE = `The account you are using is associated with an agency that is not currently a member of the Infinitec Coalition. Your access to content is limited.  If you believe this is an error, email infinitecwebsupport@ucpnet.org for assistance.  If your agency is interested in becoming a member, visit our membership information site at Myinfinitec.com.`;

export const isSuperAdmin = user => {
  if (isAuthenticated()) {
    const { roles } = user || getUserData();
    return roles.indexOf(ROLE_SUPER_ADMIN) > -1;
  }
  return false;
};

export const isUCPAdmin = user => {
  if (isAuthenticated()) {
    const { roles } = user || getUserData();
    return roles.indexOf(ROLE_UCP_ADMIN) > -1 || isSuperAdmin(user);
  }
  return false;
};

export const isContentAdmin = user => {
  if (isAuthenticated()) {
    const { roles } = user || getUserData();
    return roles.indexOf(ROLE_CONTENT_ADMIN) > -1 || isSuperAdmin(user);
  }
  return false;
};

export const isQuizAdmin = user => {
  if (isAuthenticated()) {
    const { roles } = user || getUserData();
    return roles.indexOf(ROLE_QUIZ_ADMIN) > -1 || isSuperAdmin(user);
  }
  return false;
};

export const isTrainingAdmin = user => {
  if (isAuthenticated()) {
    const { roles } = user || getUserData();
    return roles.indexOf(ROLE_TRAINING_ADMIN) > -1 || isSuperAdmin(user);
  }
  return false;
};

export const isUserAdmin = user => {
  if (isAuthenticated()) {
    const { roles } = user || getUserData();
    return roles.indexOf(ROLE_USER_ADMIN) > -1 || isSuperAdmin(user);
  }
  return false;
};

export const isUser = user => {
  if (isAuthenticated()) {
    const { roles } = user || getUserData();
    return roles.indexOf(ROLE_USER) > -1 || isSuperAdmin(user);
  }
  return false;
};

const ROLE_MAP = {
  [ROLE_SUPER_ADMIN]: isSuperAdmin,
  [ROLE_UCP_ADMIN]: isUCPAdmin,
  [ROLE_CONTENT_ADMIN]: isContentAdmin,
  [ROLE_QUIZ_ADMIN]: isQuizAdmin,
  [ROLE_TRAINING_ADMIN]: isTrainingAdmin,
  [ROLE_USER_ADMIN]: isUserAdmin,
  [ROLE_USER]: isUser,
};

export const userHasRole = role => {
  try {
    const user = getUserData();
    return ROLE_MAP[role](user);
  } catch (e) {
    return false;
  }
};

/**
 * Checks that the User has a valid contract
 * @return {boolean}
 */
export function userIsContracted() {
  try {
    const user = getUserData();
    const userAccessLevels = user?.accessLevel?.allowedAccessLevels ?? [];

    return userAccessLevels.includes(ACCESS_LEVEL_MEMBER);
  } catch (e) {
    return false;
  }
}

/**
 * Checks that the User is a member of the Minnesota Association of School Administrators.
 * @return {boolean}
 */
export function userIsMasaMember() {
  try {
    return Boolean(getUserData()?.isMasaMember);
  } catch (e) {
    return false;
  }
}

export const USER_ROLE = {
  ROLE_SUPER_ADMIN,
  ROLE_UCP_ADMIN,
  ROLE_CONTENT_ADMIN,
  ROLE_QUIZ_ADMIN,
  ROLE_TRAINING_ADMIN,
  ROLE_USER_ADMIN,
  ROLE_USER,
};
