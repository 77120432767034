import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from 'contexts/authContext';

function useLogoutReferrer() {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const queryParams = useLocation().search;
  const [referrer, setReferrer] = useState(new URLSearchParams(queryParams).get('referrer'));

  useEffect(() => {
    if (isAuthenticated && referrer) {
      setReferrer(null);
      logout();
    }
  }, [isAuthenticated, referrer, setReferrer, logout]);

  return { queryParams, referrer };
}

export default useLogoutReferrer;
