import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from 'utils/auth';
import { USER_ROLE, userHasRole, userIsContracted } from 'utils/roles';
import { useLocation } from 'react-router-dom';

const propTypes = {
  requiresContract: PropTypes.bool,
  role: PropTypes.oneOf(Object.values(USER_ROLE)),
};

function PrivateRoute({ requiresContract, roles, component: Component, ...rest }) {
  const queryParams = useLocation().search;

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() && roles?.some(role => userHasRole(role)) && (!requiresContract || userIsContracted()) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: isAuthenticated() ? `/403` : `/login`,
              search: queryParams.replace(/[?&]referrer=[a-zA-Z]*/, ''),
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = propTypes;

export default PrivateRoute;
