import { faHome, faAward, faFileAlt, faUserCog, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { isTrainingAdmin, isUCPAdmin, userIsContracted } from 'utils/roles';
import { getUserData } from 'utils/auth';
import get from 'lodash/get';

const tabs = [
  {
    id: 1,
    icon: faHome,
    path: '/dashboard',
    title: 'My Dashboard',
    order: 1,
  },
  {
    id: 2,
    icon: faFileAlt,
    path: '/online-classroom',
    title: 'Online Classroom',
    order: 2,
  },
  {
    id: 3,
    icon: faAward,
    path: '/my-results',
    title: 'My Results',
    order: 3,
  },
  {
    id: 4,
    icon: faCalendar,
    path: '/events',
    title: 'Events',
    requiresContract: true,
    order: 4,
    accessCheck: () => {
      return userIsContracted();
    },
  },
  {
    id: 5,
    icon: faUserCog,
    path: '/engage-admin',
    title: 'ENGAGE Admin',
    requiresContract: true,
    order: 9,
    accessCheck: () => {
      const userData = getUserData();
      const hasEngage = get(userData, 'memberAgency.hasEngage');
      const hasRoles = (isTrainingAdmin() || isUCPAdmin()) && userIsContracted();

      return hasRoles && hasEngage;
    },
  },
];

export default tabs;
