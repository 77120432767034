import React, { lazy, Suspense, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import { USER_ROLE } from 'utils/roles';
import { buildSidebarTree } from 'services/CustomPages';
import flattenDeep from 'lodash/flattenDeep';
import useLogoutReferrer from 'components/PrivateRoute/useLogoutReferrer';

const Dashboard = lazy(() => import('routes/Dashboard'));
const CustomPage = lazy(() => import('routes/CustomPage'));

const allRoles = Object.values(USER_ROLE);
const ENGAGE_ADMIN_ROLES = [USER_ROLE.ROLE_SUPER_ADMIN, USER_ROLE.ROLE_UCP_ADMIN, USER_ROLE.ROLE_TRAINING_ADMIN];

function Routes({ customPages }) {
  useLogoutReferrer();

  const rootCustomPages = useMemo(() => customPages.filter(page => page.parent === null), [customPages]);
  const sidebarTree = useMemo(() => buildSidebarTree(rootCustomPages, customPages), [rootCustomPages, customPages]);

  // Flatten so we can iterate over the array for the routes,
  // but still have menuRoot for use with the sidebar.
  const flattenedTree = useMemo(
    () => flattenDeep(sidebarTree.map(root => [root, ...root.children.map(child => [child, ...child.children])])),
    [sidebarTree]
  );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <PrivateRoute path='/' component={Dashboard} exact roles={allRoles} />
        <PrivateRoute path='/dashboard' component={Dashboard} exact roles={allRoles} />
        <PrivateRoute
          path='/online-classroom'
          component={lazy(() => import('routes/Activities/OnlineClassroom'))}
          exact
          roles={allRoles}
        />
        <Route path='/module/:moduleId' component={lazy(() => import('routes/Module'))} exact roles={allRoles} />
        <PrivateRoute
          path='/quiz/:moduleId/intro'
          component={lazy(() => import('routes/Activities/Quizzes'))}
          roles={allRoles}
          exact
        />
        <PrivateRoute
          path='/quiz/:moduleId/questions'
          component={lazy(() => import('routes/Activities/Quizzes/QuizQuestion'))}
          roles={allRoles}
          exact
        />
        <PrivateRoute
          path='/quiz/:moduleId/results'
          component={lazy(() => import('routes/Activities/Quizzes/QuizResults'))}
          roles={allRoles}
          exact
        />
        <PrivateRoute
          path='/my-results'
          component={lazy(() => import('routes/MyResults/QuizHistory'))}
          roles={allRoles}
          exact
        />
        <Route path='/user-badge/:userBadgeId' component={lazy(() => import('routes/MyResults/UserBadge'))} exact />
        <Route path='/transcripts/:userId/badges' component={lazy(() => import('routes/MyResults/PublicUserBadges'))} />
        <Route exact path='/quiz-history'>
          <Redirect to='/my-results' />
        </Route>
        <PrivateRoute
          path='/events'
          component={lazy(() => import('routes/Events'))}
          exact
          roles={allRoles}
          requiresContract={true}
        />
        <PrivateRoute path='/profile' component={lazy(() => import('routes/Profile'))} exact roles={allRoles} />
        <PrivateRoute
          path='/learning-campaign/:learningCampaignId/forum'
          component={lazy(() => import('routes/DiscussionForum'))}
          exact
          roles={allRoles}
          requiresContract={true}
        />
        <PrivateRoute
          path='/isbe-survey/:quizAttemptId'
          component={lazy(() => import('routes/IsbeSurvey'))}
          exact
          roles={allRoles}
        />

        {/* Engage Admin Routes */}
        <PrivateRoute
          path='/engage-admin'
          component={lazy(() => import('routes/EngageAdmin/Dashboard'))}
          exact
          roles={ENGAGE_ADMIN_ROLES}
          requiresContract={true}
        />
        <PrivateRoute
          path='/engage-admin/learning-path/create'
          component={lazy(() => import('routes/EngageAdmin/LearningPath/Create'))}
          exact
          roles={ENGAGE_ADMIN_ROLES}
          requiresContract={true}
        />
        <PrivateRoute
          path='/engage-admin/learning-path/edit/:id'
          component={lazy(() => import('routes/EngageAdmin/LearningPath/Edit'))}
          exact
          roles={ENGAGE_ADMIN_ROLES}
          requiresContract={true}
        />
        <PrivateRoute
          path='/engage-admin/inf-learning-template/edit/:id'
          component={lazy(() => import('routes/EngageAdmin/LearningPath/Copy'))}
          exact
          roles={ENGAGE_ADMIN_ROLES}
          requiresContract={true}
        />
        <PrivateRoute
          path='/engage-admin/learning-campaign/create/:templateId?'
          component={lazy(() => import('routes/EngageAdmin/LearningCampaign/Create'))}
          exact
          roles={ENGAGE_ADMIN_ROLES}
          requiresContract={true}
        />
        <PrivateRoute
          path='/engage-admin/learning-campaign/edit/:learningCampaignId'
          component={lazy(() => import('routes/EngageAdmin/LearningCampaign/Edit'))}
          exact
          roles={ENGAGE_ADMIN_ROLES}
          requiresContract={true}
        />

        <Route path='/register' component={lazy(() => import('routes/Register'))} exact />
        <Route path='/login' component={lazy(() => import('routes/Login'))} exact />
        <Route exact path='/logout'>
          <Redirect to='/login' />
        </Route>
        <Route path='/forgot-password/:resetToken?' component={lazy(() => import('routes/ForgotPassword'))} exact />
        <Route path='/terms-and-conditions' component={lazy(() => import('routes/TermsAndConditions'))} exact />

        {/* custom pages */}
        {flattenedTree.map(page => {
          return (
            <Route
              key={`page-${page.slug}`}
              path={page.url}
              render={props => (
                <CustomPage
                  {...props}
                  pageId={page.id}
                  title={page.title}
                  isInfinitextAccessPage={page.isInfinitextAccessPage}
                  mainBody={page.customBody}
                  rightRail={page.customRightRail}
                  sidebarKey={page.menuRoot || page.id}
                  sidebarTree={sidebarTree}
                />
              )}
              exact
            />
          );
        })}

        {/* error pages */}
        <Route path='/404' component={lazy(() => import('routes/ErrorPages/NotFoundPage'))} />
        <Route path='/403' component={lazy(() => import('routes/ErrorPages/ForbiddenPage'))} />
        <Route path='/500' component={lazy(() => import('routes/ErrorPages/ServerErrorPage'))} />
        <Redirect to='/404' />
      </Switch>
    </Suspense>
  );
}

export default Routes;
