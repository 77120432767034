import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import { down, up } from 'styled-breakpoints';
import { AuthContext } from 'contexts/authContext';
import IconTabs from 'components/Tabs/IconTabs';
import tabs from './tabs';
import logoImg from 'assets/images/infinitec-logo.jpeg';
import sortBy from 'lodash/sortBy';

const UserNavDetails = styled.span`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const StyledNavbar = styled(Navbar)`
  background: ${theme('colors.white')};
  box-shadow: ${theme('boxShadow')};
  height: 5rem;
  padding: 0;
`;

const NavbarContainer = styled.div`
  display: flex;
  height: inherit;
  margin: 0 auto;
  max-width: ${theme('breakpoints.lgDesktop')};
  padding: 0 2.5rem;
  width: 100%;
  ${down('smTablet')} {
    justify-content: space-between;
  }
  .navbar-toggler {
    border: none;
  }
`;

const StyledNavbarBrand = styled(NavbarBrand)`
  height: 100%;
  padding: 1rem 0;
`;

const LogoImg = styled.img`
  max-width: 8.25rem;
  height: 100%;
`;

const StyledNav = styled(Nav)`
  align-items: center;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  display: none;

  ${up('lgTablet')} {
    display: flex;
  }
`;

const StyledCollapse = styled(Collapse)`
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 5rem;
`;

const StyledNavMobile = styled(Nav)`
  background: white;
  width: 100%;
  box-shadow: ${theme('boxShadow')};
`;

const TabsContainer = styled.div`
  display: flex;
  height: 100%;
`;

const TabsContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledToggle = styled(DropdownToggle)`
  margin-left: 1rem;
  &&& {
    color: ${theme('colors.black')};
    &:hover {
      color: ${theme('colors.black')};
    }
  }
`;

const ProfileIcon = styled(FontAwesomeIcon).attrs({
  icon: faUserCircle,
})``;

const Navigation = ({ customPages }) => {
  const { isAuthenticated } = React.useContext(AuthContext);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const toggleNav = () => setIsNavOpen(!isNavOpen);

  const tabsWithCustomPages = [
    ...tabs,
    ...customPages.map((page, i) => ({
      id: tabs.length + i + 1,
      icon: null,
      path: page.url,
      title: page.title,
      order: 5 + i,
    })),
  ];

  return (
    <>
      <StyledNavbar light fixed={'top'} expand='lg' aria-label='Desktop Navigation'>
        <NavbarContainer>
          <StyledNavbarBrand tag={Link} to='/'>
            <LogoImg src={logoImg} alt={'Infinitec Logo'} />
          </StyledNavbarBrand>
          <StyledNav navbar>
            {!isAuthenticated && (
              <NavLink className='nav-link' to='/login'>
                Login
              </NavLink>
            )}
            {isAuthenticated && (
              <>
                <TabsContainer>
                  <IconTabs tabs={sortBy(tabsWithCustomPages, 'order')} />
                </TabsContainer>
                <UncontrolledDropdown nav inNavbar>
                  <StyledToggle nav caret>
                    <ProfileIcon title='My Account' />
                    <UserNavDetails>My Account</UserNavDetails>
                  </StyledToggle>
                  <DropdownMenu right>
                    <DropdownItem tag={Link} to='/profile'>
                      My Profile
                    </DropdownItem>
                    <DropdownItem tag={Link} to='/logout'>
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            )}
          </StyledNav>

          <NavbarToggler onClick={toggleNav} />
        </NavbarContainer>
      </StyledNavbar>
      <StyledCollapse isOpen={isNavOpen} navbar role='navigation' aria-label='Mobile Navigation'>
        <StyledNavMobile navbar>
          {!isAuthenticated && (
            <NavLink className='nav-link' to='/login'>
              Login
            </NavLink>
          )}
          {isAuthenticated && (
            <>
              <TabsContainerMobile>
                <IconTabs tabs={tabsWithCustomPages} isMobile={true} />
              </TabsContainerMobile>
              <UncontrolledDropdown nav inNavbar>
                <StyledToggle nav caret>
                  <ProfileIcon title='My Account' />
                  <UserNavDetails>My Account</UserNavDetails>
                </StyledToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <NavLink className='nav-link' to='/profile' onClick={toggleNav}>
                      My Profile
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink className='nav-link' to='/logout' onClick={toggleNav}>
                      Logout
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          )}
        </StyledNavMobile>
      </StyledCollapse>
    </>
  );
};

export default Navigation;
