import { useApolloClient, useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { clearSessionStorage, getUserData, isAuthenticated, setToken } from 'utils/auth';
import useInterval from 'hooks/useInterval';
import { RENEW_JWT_TOKEN } from 'services/Auth/useRenewJWT';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

export const AuthContext = React.createContext();

/**
 * @param jwt
 * @param setIsAuth
 * @param setMustConfirmInfo
 */
function login(jwt, setIsAuth, setMustConfirmInfo) {
  if (jwt) {
    setToken(jwt);
    setIsAuth(true);
    setMustConfirmInfo(!jwtDecode(jwt).confirmUserInfo);
  }
}

/**
 * @param setIsAuth
 * @param setLogoutInterval
 * @param setWarningInterval
 * @param clearIntervals
 * @param apolloClient
 */
function logout(
  isAuth,
  setIsAuth,
  setLogoutInterval,
  setWarningInterval,
  apolloClient,
  logoutInterval,
  warningInterval
) {
  const cachePromise = apolloClient.resetStore();
  cachePromise.finally(() => {
    clearSessionStorage();
    setIsAuth(false);
    logoutInterval && setLogoutInterval(null);
    warningInterval && setWarningInterval(null);
  });
  return cachePromise;
}

/**
 * Logs out users with an expired jwt on load
 *
 * @param handleLogout
 * @param setSessionWillTerminate
 */
function handleExpiredJWT(handleLogout, setSessionWillTerminate) {
  const expiration = getUserData()?.exp;

  if (expiration) {
    const expirationMoment = moment(expiration * 1000);
    const tokenExpiration = expirationMoment.diff(moment().utc());

    if (tokenExpiration < 0) {
      handleLogout().finally(() => {
        setSessionWillTerminate(false);
        window.location = '/login';
      });
    }
  }
}

export function AuthProvider({ children }) {
  const [isAuth, setIsAuth] = useState(isAuthenticated());
  const [mustConfirmInfo, setMustConfirmInfo] = useState(!getUserData()?.confirmUserInfo);
  const [warningModelOpen, setWarningModalOpen] = useState(false);
  const [sessionWillTerminate, setSessionWillTerminate] = useState(false);
  const [logoutInterval, setLogoutInterval] = useInterval(null);
  const [warningInterval, setWarningInterval] = useInterval(null);
  const [renewMutation] = useMutation(RENEW_JWT_TOKEN);
  const apolloClient = useApolloClient();

  const clearIntervals = useCallback(() => {
    // clear intervals
    setLogoutInterval(null);
    setWarningInterval(null);
  }, [setLogoutInterval, setWarningInterval]);

  const handleLogin = useCallback(jwt => login(jwt, setIsAuth, setMustConfirmInfo), []);

  const handleLogout = useCallback(
    () =>
      logout(isAuth, setIsAuth, setLogoutInterval, setWarningInterval, apolloClient, logoutInterval, warningInterval),
    [setLogoutInterval, setWarningInterval, apolloClient]
  );

  const renewJWT = useCallback(async () => {
    const res = await renewMutation();
    const { jwt } = res.data.renew;

    handleLogin(jwt);
    clearIntervals();
  }, [clearIntervals, handleLogin, renewMutation]);

  // logs out users with an expired jwt on load
  useEffect(() => {
    handleExpiredJWT(handleLogout, setSessionWillTerminate);
  }, [handleLogout, setSessionWillTerminate]);

  // manages auth intervals
  useEffect(() => {
    const expiration = getUserData()?.exp;
    if (expiration) {
      // warning interval logic
      const newWarningInterval = setInterval(() => {
        const fiveMinuteWarningMoment = moment(expiration * 1000).subtract(5, 'minutes');
        const tokenExpirationWarning = fiveMinuteWarningMoment.diff(moment().utc());

        if (tokenExpirationWarning < 0 && !sessionWillTerminate) {
          setWarningModalOpen(true);
        }
      }, 60 * 1000);
      setWarningInterval(newWarningInterval);

      // logout interval logic
      const newLogoutInterval = setInterval(() => {
        const expirationMoment = moment(expiration * 1000);
        const tokenExpiration = expirationMoment.diff(moment().utc());

        if (tokenExpiration < 0 || sessionWillTerminate) {
          setSessionWillTerminate(false);
          window.location = '/logout';
        }
      }, 60 * 1000);
      setLogoutInterval(newLogoutInterval);

      return () => {
        clearInterval(newWarningInterval);
        clearInterval(newLogoutInterval);
      };
    }
  }, [isAuth, warningModelOpen, sessionWillTerminate, setLogoutInterval, setWarningInterval, renewJWT]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuth,
        handleLogin: handleLogin,
        logout: handleLogout,
        warningModelOpen,
        setWarningModalOpen,
        clearIntervals,
        setSessionWillTerminate,
        userMustConfirmInfo: mustConfirmInfo,
        setUserMustConfirmInfo: setMustConfirmInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const AuthConsumer = AuthContext.Consumer;
