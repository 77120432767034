import { useQuery, gql } from '@apollo/client';
import { useState } from 'react';
import { isAuthenticated } from 'utils/auth';

const GET_CUSTOM_PAGES = gql`
  query customPages {
    customPages {
      id
      parent
      slug
      url
      title
      mainBody
      rightRail
      isInfinitextAccessPage
      customBody
      customRightRail
    }
  }
`;

function useGetCustomPages() {
  const [loading, setLoading] = useState(isAuthenticated());
  const [customPages, setCustomPages] = useState([]);

  useQuery(GET_CUSTOM_PAGES, {
    skip: !isAuthenticated(),
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setCustomPages(data.customPages);
      setLoading(false);
    },
    onError: err => setLoading(false),
  });

  return { loading, customPages };
}

export default useGetCustomPages;
