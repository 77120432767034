import React from 'react';
import { NavItem as BaseNavItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import Link from './styled';

const NavItem = styled(BaseNavItem)`
  height: 100%;
`;

const NavItemMobile = styled(BaseNavItem)`
  &&& a.active {
    color: ${theme('colors.white')};
    background: ${theme('colors.primary')};
  }

  &&&:hover {
    a {
      color: ${theme('colors.white')};
    }
    background: ${theme('colors.primary')};
  }
`;

const NavIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

const propTypes = {
  isMobile: PropTypes.bool,
  toggleNav: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      icon: PropTypes.object,
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      role: PropTypes.string,
    })
  ),
};

const defaultProps = {
  tabs: [],
};

const IconTabs = ({ tabs, isMobile = false }) => {
  const Component = isMobile ? NavItemMobile : NavItem;

  return (
    <>
      {tabs.map(tab => {
        if (tab.accessCheck && !tab.accessCheck()) {
          return null;
        }

        return (
          <Component key={tab.id}>
            <Link height='full' to={tab.path}>
              {tab.icon && <NavIcon icon={tab.icon} title={tab.title} />}
              {tab.title}
            </Link>
          </Component>
        );
      })}
    </>
  );
};

IconTabs.propTypes = propTypes;
IconTabs.defaultProps = defaultProps;

export default IconTabs;
